<template>
  <div class="form-group w-100" :class="$attrs.class">
    <label :for="'floating_standard_' + id" class="form-label" v-if="label"
      >{{ label }}&nbsp;<span v-if="required" style="color: red">*</span>
    </label>
    <input
      :id="id"
      ref="input"
      v-bind="{ ...$attrs, class: null }"
      :readonly="isReadonly"
      class="form-control"
      :class="{ error: error }"
      :type="type"
      :value="value"
      :style="
        isButton
          ? 'cursor: pointer;'
          : shouldHighlight
          ? 'border: 1px solid #2957a4;'
          : ''
      "
      @input="handleInput"
      @keypress="onKeypress"
      :maxLength="maxLength"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

export default {
  inheritAttrs: true,

  props: {
    showPassword: { type: Boolean, default: false },
    shouldHighlight: { type: Boolean, default: false },
    isButton: { type: Boolean, required: false },
    required: { type: Boolean, required: false },
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`;
      },
    },
    type: {
      type: String,
      default: "text",
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },

    floatingLabel: Boolean,
    error: String,
    label: String,
    value: String,
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    maxLength: {
      type: Number,
      default: 200, // Default character limit
    },
    limitToPositiveNumbers: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input", "child-event"],
  methods: {
    onKeypress(event) {
      if (
        !!this.limitToPositiveNumbers &&
        (event.charCode < 48 || event.charCode > 57)
      ) {
        event.preventDefault();
      }
    },
    togglePassword() {
      this.$emit("child-event", this.showPassword);
    },
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end);
    },
    handleInput(event) {
      const inputValue = event.target.value;
      const maxLength = this.maxLength; // Assuming maxLength is a property in your component
      if (inputValue.length > maxLength) {
        event.target.value = inputValue.slice(0, maxLength); // Set the input value to the truncated value
      } else {
        this.$emit("input", inputValue); // Emit an event to update the model value
      }
    },
  },
};
</script>

<style scoped>
.tooltip {
  border-radius: 50%;
  font-size: 0.6rem;
  padding: 2px 4px 2px 4px;
  background-color: black;
  color: white;
}

.form-error {
  color: red;
}
</style>
